import { useState, ChangeEvent } from "react";
import { Typography, TextField } from "@mui/material";
import ImageUploader from "../ImageUploader.tsx";
import NextButton from "../NextButton.tsx";
import { StepFourStyle } from "./StepFourStyle.tsx";
import {
  BookingStepFourProps,
  BookingStepFour,
} from "./StepFourInterfaces.tsx";

export default function StepFour(props: BookingStepFourProps) {
  const classes = StepFourStyle();

  const [selectedValue, setSelectedValue] = useState<string>(""); // State to track the selected value
  const [selectedImage, setSelectedImage] = useState<string>(""); // State to track the selected value

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // Assuming you're updating a property of selectedValue
    setSelectedValue(event.target.value);
  };

  function handleClick() {
    const bookingInfo: BookingStepFour = {
      information: selectedValue,
      image: selectedImage,
    };

    props.addBooking(bookingInfo);
  }

  const handleImageUpload = (imageData: string) => {
    // Do something with the uploaded image data
    setSelectedImage(imageData);
  };

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variant="h5" component="h5" align="left" gutterBottom>
            Select the scenario that describes you best.
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Your online facial consultation with a clinician is at £40 and for
            30 minutes.
          </Typography>
        </div>
        <div className={classes.fieldContainer}>
          <TextField
            id="standard-multiline-static"
            placeholder="Skriv information om vad du har för problem"
            multiline
            rows={4}
            value={selectedValue ? selectedValue : ""} // Adjust 'propertyName' to the actual property you want to display
            onChange={handleChange}
            variant="outlined"
          />
          <ImageUploader onImageUpload={handleImageUpload} />
        </div>
      </div>
      <div>
        <NextButton handleClick={handleClick} text="" />
      </div>
    </section>
  );
}
