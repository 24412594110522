import { useState } from "react";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import NextButton from "../NextButton";
import { StepOneStyle } from "./StepOneStyle";
import { BookingStepOneProps, BookingStepOne } from "./StepOneInterfaces";

function StepOne(props: BookingStepOneProps) {
  const classes = StepOneStyle();

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleClick() {
    const bookingInfo: BookingStepOne = {
      name: formData.name,
      date: formData.dob,
    };

    props.addBooking(bookingInfo);
  }
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variant="h5" component="h5" align="left" gutterBottom>
            PERSONALISED, MEDICAL GRADE SKINCARE RECOMMENDATIONS
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            GetHarley believes your skincare decisions should be powered by
            knowledge not hype. We are the first telehealth platform connecting
            individuals to experienced clinicians for a personalised discovery
            of products and treatments for teenage acne all the way to graceful
            ageing.{" "}
          </Typography>
        </div>
        <div className={classes.fieldContainer}>
          <label htmlFor="name">Name:</label>
          <TextField
            type="text"
            id="name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="dob">Date of Birth:</label>
          <TextField
            type="date"
            id="dob"
            name="dob"
            variant="outlined"
            value={formData.dob}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div>
        <NextButton handleClick={handleClick} text="" />
      </div>
    </section>
  );
}

export default StepOne;
