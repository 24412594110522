import React, { useState } from "react";

interface ImageUploaderProps {
  onImageUpload: (image: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        setSelectedImage(imageDataUrl);
        onImageUpload(imageDataUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {selectedImage && (
        <img src={selectedImage} alt="Uploaded" style={{ maxWidth: "300px" }} />
      )}
    </div>
  );
};

export default ImageUploader;
