import { Button } from "@mui/material";
import { MouseEventHandler } from "react";

function NextButton(props: { handleClick: MouseEventHandler; text: string }) {
  return (
    <div
      style={{
        left: "0px",
        right: "0px",
        bottom: "0px",
        zIndex: "200",
        position: "fixed",
        backgroundColor: "#86Ac97",
        textAlign: "center",
      }}
    >
      <Button
        onClick={props.handleClick}
        style={{
          height: "100%",
          color: "black",
          textDecoration: "none",
          fontFamily: "monospace",
          padding: "10px",
          width: "100%",
        }}
      >
        {props.text !== "" ? props.text : "Nästa"}
      </Button>
    </div>
  );
}

export default NextButton;
