import {
  Container,
  Typography,
  Button,
  Grid,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, List, ListItem, ListItemText } from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShippingOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(200, 189, 161)",
    },
  },
});

const useStyles = makeStyles({
  section: {
    backgroundColor: "white",
    color: "#000",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    width: "10%",
  },
  step: {
    fontSize: "20px",
    lineHeight: "1.15rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "capitalize",
    color: "#000000",
    marginBottom: "1rem",
    display: "block",
  },
  header: {
    fontSize: "22px",
    lineHeight: "1.4rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
  },
  subText: {
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    display: "block",
  },
  button: {
    alignItems: "center",
    textDecoration: "none",
    padding: theme.spacing(0, 2),
    color: "black !important",
    transition: "background-color, border .25s !important",
    paddingTop: "0.5rem !important",
    paddingBottom: "0.5rem !important",
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
    minWidth: "205px !important",
    height: "45px !important",
    display: "inline-block !important",
    border: "none !important",
    cursor: "pointer !important",
    backgroundColor: "#F7F0E9 !important",
    borderRadius: "0",
  },
  container: {
    display: "flex",
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "0 !important",
  },
  textContainer: {
    paddingTop: "2.5rem",
    paddingBottom: "2.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  buttonContainer: {
    display: "flex",
    height: "8.7vh",
    fontFamily: "sans-serif",
  },
});

function NavBarVeritcal() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const classes = useStyles();
  if (!isMobile) {
    return (
      <section className={classes.section}>
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <Grid container spacing={2} justifyContent="left">
              <Grid item>
                <Typography
                  variant="h4"
                  component="h4"
                  align="left"
                  gutterBottom
                  fontWeight={800}
                  padding={0}
                  letterSpacing={"5px"}
                >
                  LUNE{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  component={Link}
                  to="/"
                  style={{
                    textDecoration: "none",
                    fontFamily: "monospace",
                    padding: "10px",
                    color: "black",
                  }}
                >
                  Boka
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <AppBar
        position="static"
        style={{ height: "100%", backgroundColor: "#fff", color: "black" }}
      >
        <div className={classes.toolbar}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              fontFamily: "monospace",
              padding: "10px",
              color: "black",
            }}
          >
            Menu
          </Link>
          <Typography
            variant="h4"
            component="h4"
            align="left"
            gutterBottom
            fontWeight={800}
            padding={0}
            letterSpacing={"5px"}
          >
            LUNE{" "}
          </Typography>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              fontFamily: "monospace",
              padding: "10px",
              color: "black",
            }}
          >
            Boka
          </Link>
        </div>
      </AppBar>
    );
  }
}

export default NavBarVeritcal;
