import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import BookingStepOnePage from "./StepOne/StepOne.tsx";
import BookingStepTwoPage from "./StepTwo/StepTwo.tsx";
import BookingStepThreePage from "./StepThree/StepThree.tsx";
import BookingStepFourPage from "./StepFour/StepFour.tsx";
import BookingStepFivePage from "./StepFive/StepFive.tsx";
import Stepper from "./Stepper.tsx";
import BookingNavBar from "./BookingNavBar/BokningNavBar.tsx";
import BookingConformation from "./Confirmation/BookingConfirmation.tsx";
import { BookingStepFive } from "./StepFive/StepFiveInterfaces.tsx";
import { BookingStepOne } from "./StepOne/StepOneInterfaces.tsx";
import { BookingStepTwo } from "./StepTwo/StepTwoInterfaces.tsx";
import { BookingStepThree } from "./StepThree/StepThreeInterfaces.tsx";
import { BookingStepFour } from "./StepFour/StepFourInterfaces.tsx";

export default function ProgressMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const [bookingStepOne, setBookingStepOne] = useState<BookingStepOne | null>(
    null
  );
  const [bookingStepTwo, setBookingStepTwo] = useState<BookingStepTwo | null>(
    null
  );
  const [bookingStepThree, setBookingStepThree] =
    useState<BookingStepThree | null>(null);
  const [bookingStepFour, setBookingStepFour] =
    useState<BookingStepFour | null>(null);
  const [bookingStepFive, setBookingStepFive] =
    useState<BookingStepFive | null>(null);

  function addBookingStepOne(info: BookingStepOne) {
    handleNext();
    console.log(activeStep);
    setBookingStepOne(info);
  }

  function addBookingStepTwo(info: BookingStepTwo) {
    handleNext();
    console.log(activeStep);
    setBookingStepTwo(info);
  }

  function addBookingStepThree(info: BookingStepThree) {
    handleNext();
    console.log(activeStep);
    setBookingStepThree(info);
  }

  function addBookingStepFour(info: BookingStepFour) {
    handleNext();
    console.log(activeStep);
    setBookingStepFour(info);
  }

  function addBookingStepFive(info: BookingStepFive) {
    handleNext();
    console.log(activeStep);
    setBookingStepFive(info);
  }
  useEffect(() => {}, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <BookingNavBar handleBack={handleBack} activeStep={activeStep} />
      <Stepper step={activeStep} />

      {activeStep === 0 ? (
        <div className="boxContent">
          <BookingStepOnePage addBooking={addBookingStepOne} />
        </div>
      ) : null}
      {activeStep === 1 ? (
        <div className="boxContent">
          <BookingStepTwoPage addBooking={addBookingStepTwo} />
        </div>
      ) : null}
      {activeStep === 2 ? (
        <div className="boxContent">
          <BookingStepThreePage addBooking={addBookingStepThree} />
        </div>
      ) : null}
      {activeStep === 3 ? (
        <div className="boxContent">
          <BookingStepFourPage addBooking={addBookingStepFour} />
        </div>
      ) : null}
      {activeStep === 4 ? (
        <div className="boxContent">
          <BookingStepFivePage addBooking={addBookingStepFive} />
        </div>
      ) : null}
      {activeStep === 5 ? (
        <div className="boxContent">
          <BookingConformation
            bookingStepOne={bookingStepOne}
            bookingStepTwo={bookingStepTwo}
            bookingStepThree={bookingStepThree}
            bookingStepFour={bookingStepFour}
            bookingStepFive={bookingStepFive}
          />
        </div>
      ) : null}
    </div>
  );
}
