import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { BookingDiv } from "./Interfaces/BookingInterfaces";

interface ClickableDivProps {
  headline: string;
  subtext: string;
  id: number;
  selected: boolean;
  onDivClick: (
    id: number,
    clicked: boolean,
    headline: string,
    subtext: string
  ) => void;
}

const ClickableDiv: React.FC<ClickableDivProps> = ({
  id,
  selected,
  onDivClick,
  headline,
  subtext,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
    onDivClick(id, !clicked, headline, subtext); // Send both id and whether it's clicked or unclicked
  };

  return (
    <Card
      style={{
        margin: "10px",
        cursor: "pointer",
        backgroundColor: !selected ? "rgb(200, 189, 161)" : "#fff",
        opacity: "0.6",
        boxSizing: "border-box",
        borderRadius: "5px",
        outline: "none",
        flex: "1 1 45%" /* Set the width of each clickable div to 45% */,
        transition: "opacity 0.25s ease 0s",
      }}
      onClick={handleClick}
    >
      <CardContent style={{ padding: "1rem 40px", minHeight: "80px" }}>
        <Typography sx={{ fontSize: 20 }} gutterBottom>
          {headline}
        </Typography>
        <Typography sx={{ fontSize: 14 }} component="div">
          {subtext}
        </Typography>
      </CardContent>
    </Card>
  );
};

function ClickableDivGroup(props: { divs: BookingDiv[]; handleDivCLick: any }) {
  const [selectedDiv, setSelectedDiv] = useState<number | null>(null);

  const handleDivClick = (
    id: number,
    clicked: boolean,
    headline: string,
    subtext: string
  ) => {
    setSelectedDiv(id);
    props.handleDivCLick(headline, subtext);
  };

  return (
    <div
      className="parentContainer"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {props.divs.map((obj, index) => (
        <ClickableDiv
          key={index}
          id={index}
          selected={selectedDiv === index}
          onDivClick={handleDivClick}
          headline={obj.headline}
          subtext={obj.subtext}
        />
      ))}
    </div>
  );
}

export default ClickableDivGroup;
