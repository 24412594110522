import { useState } from "react";
import { Typography } from "@mui/material";
import ClickableDivGroup from "../ClickableDiv.tsx";
import NextButton from "../NextButton.tsx";
import { BookingDiv } from "../Interfaces/BookingInterfaces.ts";
import { StepThreeStyle } from "./StepThreeStyle.tsx";
import {
  BookingStepThreeProps,
  BookingStepThree,
} from "./StepThreeInterfaces.tsx";

export default function StepThree(props: BookingStepThreeProps) {
  const classes = StepThreeStyle();

  const initialBookingInfoThree: BookingStepThree = {
    category: "",
  };
  const [selectedValue, setSelectedValue] = useState<BookingStepThree>(
    initialBookingInfoThree
  );

  const divs: BookingDiv[] = [
    { headline: "Acne", subtext: "" },
    { headline: "Scarring", subtext: "" },
    { headline: "Redness", subtext: "" },
    { headline: "Dryness", subtext: "" },
    { headline: "Pigmentation", subtext: "" },
    { headline: "Sensitivity", subtext: "" },
    { headline: "Loss of firmness", subtext: "" },
    { headline: "Fine lines", subtext: "" },
    { headline: "Rosacea", subtext: "" },
    { headline: "Wrinkles", subtext: "" },
    { headline: "Skin texture", subtext: "" },
    { headline: "Others", subtext: "" },
  ];

  function handleClick() {
    props.addBooking(selectedValue);
  }

  function handleDivCLick(category: string, subtext: string) {
    const bookingInfo: BookingStepThree = {
      category: category,
    };
    setSelectedValue(bookingInfo);
  }

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variant="h5" component="h5" align="left" gutterBottom>
            Select the scenario that describes you best.
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Your online facial consultation with a clinician is at £40 and for
            30 minutes.
          </Typography>
        </div>
        <div className={classes.fieldContainer}>
          <ClickableDivGroup divs={divs} handleDivCLick={handleDivCLick} />
        </div>
      </div>
      <div>
        <NextButton handleClick={handleClick} text="" />
      </div>
    </section>
  );
}
