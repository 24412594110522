import { useState, ChangeEvent } from "react";
import {
  Typography,
  TextField,
  Card,
  CardContent,
  Box,
  Divider,
} from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import PaymentStripe from "../PaymentStripe.tsx";
import { Elements } from "@stripe/react-stripe-js";
import "../styles.css";
import { ConfirmationStyle } from "./ConfirmationStyle.tsx";
import { BookingStepFive } from "../StepFive/StepFiveInterfaces.tsx";
import { BookingStepOne } from "../StepOne/StepOneInterfaces.tsx";
import { BookingStepThree } from "../StepThree/StepThreeInterfaces.tsx";
import { BookingStepFour } from "../StepFour/StepFourInterfaces.tsx";
import { BookingStepTwo } from "../StepTwo/StepTwoInterfaces.tsx";

const stripePromise = loadStripe("pk_test_4QHSdRjQiwkzokPPCiK33eOq");

export default function BookingConformation(props: {
  bookingStepThree: BookingStepThree | null;
  bookingStepTwo: BookingStepTwo | null;
  bookingStepOne: BookingStepOne | null;
  bookingStepFour: BookingStepFour | null;
  bookingStepFive: BookingStepFive | null;
}) {
  const {
    bookingStepThree,
    bookingStepTwo,
    bookingStepOne,
    bookingStepFour,
    bookingStepFive,
  } = props;

  const classes = ConfirmationStyle();
  const [clientSecret, setClientSecret] = useState("");

  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    setSelectedValue(newValue);
  };

  const handleBooking = async () => {
    try {
      const response = await fetch("https://localhost:44312/api/Booking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          totalPrice: 300,
          customerId: 1,
          date: bookingStepFive?.date,
          summery: `${bookingStepTwo?.headline}:${bookingStepThree?.category}:${bookingStepFour?.information}`,
          image: bookingStepFour?.image,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  };
  const formattedDateTime: string = Intl.DateTimeFormat("se-SV", {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour format
  }).format(bookingStepFive?.date);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <Card style={{ boxShadow: "none", backgroundColor: "#CBC0AD" }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Sammanfattning
            </Typography>
            <Typography variant="h5" component="div">
              {bookingStepTwo?.headline}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {bookingStepThree?.category} : {bookingStepFour?.information}
              {bookingStepFour?.image != null ? " (Bild bifogad)" : ""}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {formattedDateTime}
            </Typography>
            <Typography variant="body2"> 1200 kr</Typography>
          </CardContent>
        </Card>
        <button onClick={handleBooking}>Boka</button>

        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1 } }}
          noValidate
          style={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <Divider style={{ width: "calc(100% - 24px)", padding: "1rem" }} />

          <Typography
            sx={{ fontSize: 14, paddingTop: "1em" }}
            color="text.secondary"
            gutterBottom
          >
            Uppgifter
          </Typography>
          <TextField
            InputProps={{ sx: { borderRadius: 0 } }}
            required
            defaultValue={bookingStepOne?.name}
            placeholder="Förnamn"
          />
          <TextField
            InputProps={{ sx: { borderRadius: 0 } }}
            required
            placeholder="Efternamn"
          />
          <TextField
            InputProps={{ sx: { borderRadius: 0 } }}
            required
            placeholder="Email"
          />
          <TextField
            InputProps={{ sx: { borderRadius: 0 } }}
            type="number"
            required
            placeholder="Telefon"
          />
          <Divider style={{ width: "calc(100% - 24px)", padding: "1rem" }} />
          <div>
            <Typography
              sx={{ fontSize: 14, paddingTop: "1rem" }}
              color="text.secondary"
              gutterBottom
            >
              Betalsätt
            </Typography>
            <Elements stripe={stripePromise}>
              <PaymentStripe
                onSuccess={() => console.log("Payment successful!")}
              />
            </Elements>
          </div>
        </Box>
      </div>
    </section>
  );
}
