import {
  Container,
  Typography,
  Button,
  Grid,
  createTheme,
} from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShippingOutlined";
import hotpot from "./skincare-finder.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(200, 189, 161)",
    },
  },
});

const useStyles = makeStyles({
  section: {
    color: "#fff",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    width: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "20% 50%",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  step: {
    fontSize: "20px",
    lineHeight: "1.15rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "capitalize",
    color: "#000000",
    marginBottom: "1rem",
    display: "block",
  },
  header: {
    fontFamily: "sans-serif",
    fontSize: "22px",
    lineHeight: "1.4rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
  },
  subText: {
    fontFamily: "sans-serif",
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    display: "block",
  },
  button: {
    alignItems: "center",
    textDecoration: "none",
    padding: theme.spacing(0, 2),
    color: "black !important",
    paddingTop: "0.5rem !important",
    paddingBottom: "0.5rem !important",
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
    minWidth: "205px !important",
    height: "45px !important",
    display: "inline-block !important",
    border: "none !important",
    cursor: "pointer !important",
    backgroundColor: "darkseagreen !important",
    borderRadius: "0",
  },
  container: {
    display: "flex",
  },

  textContainer: {
    paddingTop: "2.5rem",
    paddingBottom: "2.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    "@media (max-width:600px)": {
      paddingTop: "0",
      paddingBottom: "0",
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
});

function AppointmentSection() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <h1 className="homePageHeadline">Coming Soon</h1>
        </div>
      </div>
    </section>
  );
}

export default AppointmentSection;
