import React from "react";

const ContactPage: React.FC = () => {
  return (
    <div>
      <h1>Contact Page</h1>
      <p>Here you can contact us.</p>
    </div>
  );
};

export default ContactPage;
