import { makeStyles } from "@mui/styles";
import { theme } from "../theme.tsx";

export const StepThreeStyle = makeStyles({
  section: {
    backgroundColor: "white",
    color: "#000",
    height: "100vh",
    "@media (max-width:600px)": {
      height: "calc(100vh - calc(100vh - 100%))",
    },
  },
  step: {
    fontFamily: "sans-serif",
    fontSize: "20px",
    lineHeight: "1.15rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "capitalize",
    color: "#000000",
    marginBottom: "1rem",
    display: "block",
  },
  header: {
    fontFamily: "sans-serif",
    fontSize: "22px",
    lineHeight: "1.4rem",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
  },
  subText: {
    fontFamily: "sans-serif",
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000000",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    display: "block",
  },
  button: {
    alignItems: "center",
    textDecoration: "none",
    padding: theme.spacing(0, 2),
    color: "black !important",
    transition: "background-color, border .25s !important",
    paddingTop: "0.5rem !important",
    paddingBottom: "0.5rem !important",
    paddingLeft: "1.5rem !important",
    paddingRight: "1.5rem !important",
    minWidth: "205px !important",
    height: "45px !important",
    display: "inline-block !important",
    border: "none !important",
    cursor: "pointer !important",
    backgroundColor: "#F7F0E9 !important",
    borderRadius: "0",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "2.5rem 1.5rem",
    "@media (max-width:600px)": {
      flexWrap: "wrap",
    },
  },

  textContainer: {
    paddingTop: "2.5rem",
    paddingBottom: "2.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    flex: "1 1 0%",
  },
  fieldContainer: {
    display: "flex",
    flex: "2 1 0%",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
});
