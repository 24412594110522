import {
  Container,
  Typography,
  Button,
  Grid,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider, makeStyles } from "@mui/styles";
import NavBarVeritcal from "./NavBarVertical.tsx";
import HowItWorks from "./HowItWorks.tsx";
import { Link } from "react-router-dom";
import AppointmentSection from "./AppointmentSection.tsx";
import FAQPage from "./FAQPage.tsx";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(200, 189, 161)",
    },
  },
});

const useStyles = makeStyles({
  section: {
    backgroundColor: "rgb(200, 189, 161)",
    color: "#000",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    "@media (max-width:600px)": {
      flexDirection: "row",
      display: "flex",
      flexWrap: "wrap",
    },
  },
  container: {
    display: "flex",
    "@media (max-width:600px)": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
});

function HomePage() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className={classes.container}>
      <NavBarVeritcal />
      <AppointmentSection />
      {/*  <HowItWorks />*/}

      {isMobile ? (
        <div
          style={{
            left: "0px",
            right: "0px",
            bottom: "0px",
            zIndex: "200",
            position: "fixed",
            backgroundColor: "darkseagreen",
            textAlign: "center",
            padding: "1rem",
          }}
        >
          <Link
            to="/"
            style={{
              height: "100%",
              color: "black",
              textDecoration: "none",
              fontFamily: "monospace",
              padding: "10px",
            }}
          >
            Boka din onlinekonsultation
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default HomePage;
