import { AppBar, Toolbar, Typography, Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BookingNavBarStyle } from "./BookingNavBarStyle";

function BokingNavBar(props: {
  activeStep: React.SetStateAction<number>;
  handleBack: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  const classes = BookingNavBarStyle();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    setActiveStep(props.activeStep); // This will log the updated value
  }, [props.activeStep]);

  return (
    <AppBar
      position="static"
      style={{ height: "100%", backgroundColor: "#fff", color: "black" }}
    >
      <div className={classes.toolbar}>
        <Button
          variant="text"
          style={{
            visibility: props.activeStep == 0 ? "hidden" : "visible",
            textDecoration: "none",
            fontFamily: "monospace",
            padding: "10px",
            color: "black",
            fontSize: "1rem",
          }}
          onClick={props.handleBack}
        >
          <ArrowBackIosIcon />
          Tillbaka
        </Button>
        <Typography
          variant="h4"
          component="h4"
          align="left"
          fontWeight={800}
          padding={0}
          letterSpacing={"5px"}
        >
          LUNE{" "}
        </Typography>
        <Link
          to="/"
          style={{
            fontSize: "1rem",
            textTransform: "uppercase",
            textDecoration: "none",
            fontFamily: "monospace",
            padding: "10px",
            color: "black",
          }}
        >
          Avsluta
        </Link>
      </div>
    </AppBar>
  );
}

export default BokingNavBar;
