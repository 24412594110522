import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LoginPage from "./LoginPage.tsx";
import ContactPage from "./ContactPage.tsx";
import HomePage from "./Home.tsx";
import BookingPage from "./BookingSteps/BookingPage.tsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/appointment" element={<BookingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
