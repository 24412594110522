import { useState } from "react";
import Calendar from "../Calender.tsx";
import "../styles.css";
import NextButton from "../NextButton.tsx";
import { StepFiveStyle } from "./StepFiveStyle.tsx";
import {
  BookingStepFiveProps,
  BookingStepFive,
} from "./StepFiveInterfaces.tsx";

export default function StepFive(props: BookingStepFiveProps) {
  const classes = StepFiveStyle();

  const [selectedValue, setSelectedValue] = useState<Date>(new Date()); // State to track the selected value

  const showDetailsHandle = (time: string, date: Date) => {
    const [hours, minutes] = time.split(":").map(Number);

    // Create a new Date object with the same date and the specified time
    const combinedDateTime: Date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    );
    setSelectedValue(combinedDateTime);
  };

  function handleClick() {
    const bookingInfo: BookingStepFive = {
      date: selectedValue,
    };
    props.addBooking(bookingInfo);
  }
  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.fieldContainer}>
          <Calendar showDetailsHandle={showDetailsHandle} />
        </div>
      </div>
      <div>
        <NextButton handleClick={handleClick} text="" />
      </div>
    </section>
  );
}
