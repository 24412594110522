import { useState } from "react";
import { Typography } from "@mui/material";
import ClickableDivGroup from "../ClickableDiv.tsx";
import NextButton from "../NextButton.tsx";
import { BookingDiv } from "../Interfaces/BookingInterfaces.ts";
import { StepTwoStyle } from "./StepTwoStyle.tsx";
import { BookingStepTwoProps, BookingStepTwo } from "./StepTwoInterfaces.tsx";

export default function StepTwo(props: BookingStepTwoProps) {
  const classes = StepTwoStyle();

  const initialBookingInfoTwo: BookingStepTwo = {
    headline: "",
    subtext: "",
  };

  const [selectedValue, setSelectedValue] = useState<BookingStepTwo>(
    initialBookingInfoTwo
  );

  const divs: BookingDiv[] = [
    {
      headline: "Specific Facial Skin Concerns",
      subtext:
        "“I have specific skin concerns I want to address such as acne, pigmentation, scarring, etc.”",
    },
    {
      headline: "General Healthy Ageing",
      subtext:
        "“I want to take better care of my skin and future-proof my routine for healthy ageing”",
    },
    {
      headline: "Bridal Preparations",
      subtext:
        "“I want to ensure that my skin looks radiant on my wedding day”",
    },
    {
      headline: "Pregnancy & Breastfeeding",
      subtext:
        "“I want to take better care of my skin while ensuring my regimen is safe for my baby”",
    },
    {
      headline: "Menopause & Hormonal Changes",
      subtext:
        "“I want to take better care of my skin as I go through hormonal changes”",
    },
  ];

  function handleDivCLick(headline: string, subtext: string) {
    const bookingInfo: BookingStepTwo = {
      headline: headline,
      subtext: subtext,
    };
    setSelectedValue(bookingInfo);
  }

  function handleClick() {
    props.addBooking(selectedValue);
  }

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variant="h5" component="h5" align="left" gutterBottom>
            Select the scenario that describes you best.
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            Your online facial consultation with a clinician is at £40 and for
            30 minutes.
          </Typography>
        </div>
        <div className={classes.fieldContainer}>
          <ClickableDivGroup divs={divs} handleDivCLick={handleDivCLick} />
        </div>
      </div>

      <NextButton handleClick={handleClick} text="" />
    </section>
  );
}
