import { makeStyles } from "@mui/styles";

export const BookingNavBarStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "0 !important",
    color: "black",
  },
  bookAppButton: {
    height: "100%",
    backgroundColor: "rgb(237, 167, 64)",
    letterSpacing: "0",
    textTransform: "none",
    color: "#000",
  },
  buttonContainer: {
    display: "flex",
    height: "8.7vh",
    fontFamily: "sans-serif",
    alignSelf: "flex-start",
  },
  button: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    height: "100%",
    padding: "10px",
    color: "black !important",
  },
}));
